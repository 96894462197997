









































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import MutationModal from '@/layouts/back-office/elements/modals/MutationModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {Audit_audit, AuditRating, CloseAuditVariables, InspectionStatus} from '@/types/intrador';
import {getInspectionStatusTranslation} from '@/plugins/inspection/InspectionStatus';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';

@Component({
    components: {InputTextarea, ActionModal, MutationModal, InputSelect},
  })
  export default class CloseAuditAction extends Mixins<ActionMixin<any>>(ActionMixin) {
    public title: string = this.$it('audit.detail.close-button.title', 'Close audit') as string;
    public icon: string = 'check';
    public permission: string = 'audits-close';

    @Prop(String) protected auditId!: string;
    @Prop({ type: Array, default: () => [] }) protected refetchQueries!: any[];
    @Prop(Function) protected refetch!: (() => Promise<any>);

    private rating: AuditRating | null = null;
    private message: string | null = null;
    private confirmed = false;
    private acceptNotClosed = true;
    private getStatusTranslation = getInspectionStatusTranslation;

    get availableItems(): any[] {
      return this.selected.filter((s) => !s.closedAt);
    }

    get audit(): Audit_audit {
      return this.selected[0];
    }

    private get holdInspection() {
      return this.audit.inspectionStatusCount.filter((status) =>
        status.name === InspectionStatus.ON_HOLD).length > 0;
    }

    private get disabledMessage() {
      if (this.holdInspection) {
        return this.$it('close.audit.action.hold.inspection', 'One or more inspections are still on hold, please check the detail page of the inspection.');
      }
    }

    private get ratingOptions() {
      return {
        [this.$it('audit.rating.content.title', 'Satisfactory') as string]: AuditRating.CONTENT,
        [this.$it('audit.rating.half-content.title', 'Satisfactory with reservations') as string]:
          AuditRating.HALF_CONTENT,
        [this.$it('audit.rating.not-content.title', 'Unsatisfactory') as string]: AuditRating.NOT_CONTENT,
      };
    }

    private get variables(): CloseAuditVariables {
      return {
        id: this.auditId,
        rating: this.rating!,
        acceptNotClosed: this.acceptNotClosed,
        message: this.message,
      };
    }

    private get canSave() {
      return this.confirmed && this.rating !== null && !this.holdInspection;
    }

    private get modal() {
      return this.$refs.actionModal as ActionModal;
    }

    public open() {
      if (this.refetch) {
        this.refetch();
      }

      this.modal.open();
    }

    public close() {
      this.modal.close();
    }

    protected onSelect() {
      // Open modal
      this.open();
    }

    // If the audit is changed, reset the form
    @Watch('auditId', {immediate: true})
    protected selectedBranchChanged() {
      this.confirmed = false;
      this.rating = null;
      this.acceptNotClosed = true;
      this.message = null;
    }

    private ratingChanged() {
      this.confirmed = false;
    }
  }
