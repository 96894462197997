
















import { Component, Mixins, Prop } from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({ components: { ConfirmActionModal } })
export default class DeleteAuditAction extends Mixins(ActionMixin) {
  public title: string = this.$it('audit.detail.delete-button.title', 'Delete audit') as string;
  public icon: string = 'times';
  public permission: string = 'audits-delete';

  @Prop({ type: Array, default: () => [] }) protected refetchQueries!: any[];

  @Prop(String) protected auditId?: string;

  private get variables() {
    return {
      id: this.auditId,
    };
  }

  private get canSave() {
    return true;
  }

  private get modal() {
    return this.$refs.actionModal as ConfirmActionModal;
  }

  public open() {
    this.modal.open();
  }

  public close() {
    this.modal.close();
  }

  protected onSelect() {
    // Open modal
    this.open();
  }

  private deleteDone() {
    this.close();
    this.$emit('done');
  }
}
