









import {Component, Prop, Vue} from 'vue-property-decorator';
import { EquipmentSituation } from '@/types/intrador';

@Component({})
export default class EquipmentSituationStatus extends Vue {
  @Prop(Object) protected inspection!: any;

  private get tooltip() {
    if (this.demoSituation || this.rentalSituation) {
      return this.$it('inspection.equipment-situation-amount.tooltip', 'Displays the number of times this asset was inspected in a row with this situation.');
    } else if (this.soldSituation) {
      return this.$it('inspection.equipment-situation-sold.tooltip', 'Displays the date that the asset has been on sold since.');
    }
  }

  private get soldSituation() {
    let attributes: any = {};
    let equipmentChecks: any = {};
    const checks = this.inspection.checks;

    equipmentChecks = checks.find((check: { namespace: string; level: string; }) =>
      check.namespace === 'SoldDate' && check.level !== 'SUCCESS');

    if (equipmentChecks) {
      attributes = JSON.parse(equipmentChecks.attributes);

      if (attributes) {
        return {
          onSoldDate: this.$id(attributes.invoice_date, 'date'),
        };
      }
    }
    const invoiceParameter = this.inspection.parameterValues.find((parameterValue: any) =>
      parameterValue.parameter.id === '899');

    if (invoiceParameter) {
      return {
        onSoldDate: this.$id(invoiceParameter.value, 'date'),
      };
    }
    return null;
  }

  private get demoSituation() {
    let attributes: any = {};
    let equipmentChecks: any = {};
    const checks = this.inspection.checks;

    equipmentChecks = checks.find((check: { namespace: string; level: string; }) =>
      check.namespace === 'TimesInDemonstration' && check.level !== 'SUCCESS');

    if (equipmentChecks) {
      attributes = JSON.parse(equipmentChecks.attributes);

      return {
        timesInDemo: attributes.times_in_demonstration,
      };
    }
    return null;
  }

  private get rentalSituation() {
    let attributes: any = {};
    let equipmentChecks: any = {};
    const checks = this.inspection.checks;

    equipmentChecks = checks.find((check: { namespace: string; level: string; }) =>
      check.namespace === 'TimesInRental' && check.level !== 'SUCCESS');

    if (equipmentChecks) {
      attributes = JSON.parse(equipmentChecks.attributes);

      return {
        timesInRental: attributes.times_in_rental,
      };
    }
    return null;
  }

  private get equipmentSituationView() {
    const checks = this.inspection.checks;
    const situation = this.inspection.equipmentSituation;

    if (Array.isArray(checks) && situation) {
      switch (situation) {
        case EquipmentSituation.SOLD:
          if (this.soldSituation) {
            return this.$it('inspection.anomaly.on-sold', this.soldSituation, 'On Sold since {onSoldDate}');
          }
          return null;

        case EquipmentSituation.RENTAL:
          if (this.rentalSituation) {
            return this.$it('inspection.anomaly.times-in-rental', this.rentalSituation, '{timesInRental} times in Rental');
          }
          return null;

        case EquipmentSituation.IN_DEMONSTRATION:
          if (this.demoSituation) {
            // tslint:disable-next-line:max-line-length
            return this.$it('inspection.anomaly.times-in-demonstration', this.demoSituation, '{timesInDemo} times in Demonstration');
          }
          return null;
      }
    }
  }
}
