var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":require('@/graphql/Audit.gql'),"variables":{ id: _vm.$route.params.auditId },"update":_vm.resolveData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(isLoading)?_c('spinner',{staticClass:"spinner"}):(error)?_c('div',[_c('div',{staticClass:"alert alert-danger",on:{"click":function($event){_vm.showError=!_vm.showError}}},[_c('div',[_vm._v(_vm._s(_vm.$it('global.error.not-found', 'The page you have requested cannot be found or you do not have the necessary permissions to access this page.')))]),(_vm.showError)?_c('div',{staticStyle:{"font-family":"Courier"}},[_vm._v(_vm._s(error))]):_vm._e()])]):(data)?_c('div',{staticClass:"page page-audits"},[_c('query-data-table',{ref:"queryDataTable",attrs:{"dataKey":"paginateAuditInspections","query":require('@/graphql/AuditInspections.gql'),"title":data.branch.name,"subtitle":((_vm.$id(data.plannedAt, 'date')) + " &mdash; " + (_vm.$id(data.dueAt, 'date'))),"variables":{ auditId: data.id, limit: 25 },"columns":_vm.columns,"filters":_vm.filters,"searchableFields":_vm.searchable,"back":{ name: _vm.$route.query.return || 'planning', query: _vm.$route.query.previousQuery || {} },"default-sort":"equipmentSituation"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_c('approve-inspections',{attrs:{"register":register,"selected":selected,"state":_vm.stateAccept}}),_c('approve-inspections',{attrs:{"register":register,"selected":selected,"state":_vm.stateDecline}}),_c('extend-inspection',{attrs:{"register":register,"selected":selected}}),_c('transfer-snapshots',{attrs:{"register":register,"selected":selected,"inspection-type":_vm.inspectionType},on:{"done":function($event){return _vm.$refs.queryDataTable.refresh()}}}),_c('cancel-inspections',{attrs:{"register":register,"selected":selected},on:{"done":function($event){return _vm.$refs.queryDataTable.refresh()}}}),_c('send-inspections-reminder-email-action',{attrs:{"register":register,"selected":selected}})]}},{key:"append-title",fn:function(){return [(data.trusted)?_c('trusted-label'):_vm._e(),(data.isOnHold)?_c('on-hold-label'):_vm._e(),_vm._v(" "+_vm._s(_vm.$id(data.plannedAt, 'date'))+" - "+_vm._s(_vm.$id(data.dueAt, 'date'))+" ")]},proxy:true},{key:"action-bar-buttons",fn:function(){return [(data.status !== _vm.statusClosed)?_c('action-button',{attrs:{"button-classes":"action-bar-button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('delete-audit-action',{attrs:{"single":"","register":register,"selected":[data],"audit-id":data.id},on:{"done":_vm.deleted}})]}}],null,false,3674643709)}):_vm._e(),(data.status !== _vm.statusClosed)?_c('action-button',{attrs:{"button-classes":"action-bar-button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('close-audit-action',{attrs:{"single":"","register":register,"selected":[data],"audit-id":data.id}})]}}],null,false,2542830276)}):_vm._e(),_c('action-button',{attrs:{"title":_vm.$it('global.more', 'More'),"multiple":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('download-audit-report-action',{attrs:{"single":"","register":register,"selected":[data],"audit":data}}),(data.status !== _vm.statusClosed)?_c('send-audit-reminder-email-action',{attrs:{"register":register,"selected":[data],"audit-id":data.id,"single":""}}):_vm._e(),_c('create-note',{attrs:{"mutation":require('@/graphql/mutations/CreateAuditNote.gql'),"register":register,"selected":[data],"single":"","upload-file":false}})]}}])})]},proxy:true},{key:"data-table-prepend",fn:function(){return [(data.status !== 'RUNNING')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(data.automaticallyClosedAt)?_c('div',{staticClass:"alert alert-success",domProps:{"innerHTML":_vm._s(_vm.$it('audit.detail.automatically-closed.message', _vm.closedParams, 'This audit was automatically closed on {closedAt} by {closedBy} with rating {rating}'))}}):_vm._e(),(data.closedBy)?_c('div',{staticClass:"alert alert-success",domProps:{"innerHTML":_vm._s(_vm.$it('audit.detail.closed.message', _vm.closedParams, 'This audit was closed on {closedAt} by {closedBy} with rating {rating}'))}}):(data.postponedTo)?_c('div',{staticClass:"alert alert-warning",domProps:{"innerHTML":_vm._s(_vm.$it('audit.detail.postponed.message', { postponedTo: ("<strong>" + (_vm.$id(data.postponedTo, 'date')) + "</strong>"), postponeReason: ("<strong>" + (_vm.getPostponeReason(data.postponeReason)) + "</strong>"), rating: _vm.getStarRating(data.rating) }, 'This audit was postponed until {postponedTo} due to reason {postponeReason} with rating {rating}'))}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"row counts"},[(data.score)?_c('div',{staticClass:"col-auto"},[_c('number-card',{staticClass:"score-amount",class:{ danger: data.score <= 50,
                         good: data.score >= 80,
                         middle: data.score < 80 && data.score > 50 },attrs:{"amount":data.score,"title":_vm.$it('audit.detail.score', 'Score')}})],1):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('number-card',{staticClass:"anomalies-amount",attrs:{"amount":data.inspectionsWithAnomaliesAmount,"title":_vm.$it('audit.detail.anomalies-amount', 'Anomalies')}})],1),_vm._l((data.inspectionStatusCount),function(status){return _c('div',{key:("audit-inspection-count-" + (status.id)),staticClass:"col-auto"},[_c('number-card',{class:("count-" + (status.name)),attrs:{"amount":status.count,"title":_vm.getStatusTranslation(status.name)}})],1)}),_c('div',{staticClass:"col"}),(data.notes.length > 0)?_c('div',{staticClass:"col-auto"},[_c('card',{staticClass:"audit-remarks",attrs:{"guttered":false}},[_c('h3',[_vm._v(_vm._s(_vm.$it('global.remarks', 'Information')))]),_c('notes-card',{attrs:{"notes":data.notes}})],1)],1):_vm._e(),(data.branch.endedAudits.length > 0)?_c('div',{staticClass:"col-auto"},[_c('card',{staticClass:"ended-audits"},[_c('last-audit',{attrs:{"audits":data.branch.endedAudits}})],1)],1):_vm._e()],2)]},proxy:true},{key:"id",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [(item.thumb)?_c('img',{staticClass:"thumbnail",attrs:{"src":item.thumb}}):_c('div',{staticClass:"thumbnail"})]}},{key:"asset.name",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('router-link',{staticClass:"row-title",attrs:{"to":{ name: 'inspections-detail', params: { id: item.id }}}},[_c('div',{staticClass:"asset-name"},[_vm._v(" "+_vm._s(value)+" ")])]),_c('div',{staticClass:"row-subtitle"},[_c('span',{attrs:{"aria-label":_vm.$it('global.vendor-invoice-number', 'Vendor Invoice Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(_vm._f("meta")(item.asset,'vendor_invoice_number')))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.contract-number', 'Contract Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(_vm._f("meta")(item.asset,'contract_number')))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.asset.serialNumber))])])]}},{key:"inspector",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [(value)?[_c('inspection-inspector-avatar',{attrs:{"inspector":value}})]:_vm._e()]}},{key:"situationStatus",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('equipment-situation-status',{attrs:{"inspection":item}})]}},{key:"equipmentSituation",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [(value)?_c('equipment-situation-icon',{attrs:{"situation":value}}):_vm._e()]}},{key:"anomaliesAmount",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('anomalies-amount',{attrs:{"status":item.status,"inspection":item,"anomalies-amount":value}})]}},{key:"score",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('div',{staticClass:"score"},[(value !== null)?_c('score',{attrs:{"score":value}}):_vm._e()],1)]}},{key:"plannedAt",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'dateTimeWithoutYear')))]),_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.dueAt, 'dateTimeWithoutYear')))])]}},{key:"status",fn:function(ref){
                         var value = ref.value;
                         var column = ref.column;
                         var item = ref.item;
return [_c('inspection-status-label',{attrs:{"status":value}},[(item.hasApprovalRemark || item.status === _vm.statusOnHold)?_c('div',{staticClass:"fas fa-comment-dots"}):_vm._e()])]}}])})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }