






























import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import FilePreview from '@/components/elements/input/FilePreview.vue';
import DownloadButton from '@/layouts/back-office/elements/download/DownloadButton.vue';

interface Note {
  id: string;
  note: string;
  internal: boolean;
  creator: any;
  file: any;
  createdAt: string;
}

@Component({
  components: {DownloadButton, Card, FilePreview},
})
export default class NotesCard extends Vue {
  @Prop(Array) protected notes!: Note[];

  protected get sortedNotes() {
    return this.notes.sort((a: Note, b: Note) => {
      return ((new Date(b.createdAt)) > (new Date(a.createdAt))) ? 1 : 0;
    });
  }
}
