import { ApolloQueryResult } from 'apollo-client';
import {Audit_audit_inspections, AuditInspectionIds, AuditInspectionIds_audit_inspections} from '@/types/intrador';
import { graphqlClient } from '@/store/apollo/apollo';

export default class AuditInspections {

  public static async getInspectionIds(id: string): Promise<string[]> {
    const previousAudit = localStorage.getItem('current-audit-id');
    let inspectionIds: string[] = [];

    if (id === previousAudit) {
      const inspectionIdsString = localStorage.getItem('current-audit-inspections');
      inspectionIds = inspectionIdsString?.split(',') ?? [];
    }

    if (inspectionIds.length === 0) {
      const audit: ApolloQueryResult<AuditInspectionIds> = await graphqlClient.query({
        query: require('@/graphql/AuditInspectionsId.gql'),
        variables: {id},
      });

      if (audit.data.audit) {
        const inspections = audit.data.audit.inspections;
        inspectionIds = inspections.map((inspection: AuditInspectionIds_audit_inspections) => inspection.id);

        AuditInspections.setInspectionIds(id, inspections);
      }
    }

    return inspectionIds;
  }

  public static setInspectionIds(id: string, inspections: AuditInspectionIds_audit_inspections[]) {
    const inspectionsOrder = inspections.map((inspection: AuditInspectionIds_audit_inspections) => inspection.id);
    localStorage.setItem('current-audit-id', id);
    localStorage.setItem('current-audit-inspections', inspectionsOrder.join(','));
  }
}
