

































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Meta} from '@sophosoft/vue-meta-decorator';
import {
  Audit,
  Audit_audit,
  AuditPostponeReason,
  AuditStatus,
  EquipmentSituation,
  InspectionApprovalState,
  InspectionStatus,
  InspectionTypeEnum,
} from '@/types/intrador';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import CloseAuditAction from '@/components/audits/actions/CloseAuditAction.vue';
import DataTable from '@/layouts/back-office/elements/datatable/DataTable.vue';
import {DataTableColumn, DataTableSearchable} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import ApproveInspections from '@/components/inspection/actions/ApproveInspections.vue';
import UpdateInspections from '@/components/inspection/actions/UpdateInspections.vue';
import TransferSnapshots from '@/components/inspection/actions/TransferInspections.vue';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import ExportSnapshots from '@/components/inspection/actions/ExportInspections.vue';
import CancelInspections from '@/components/inspection/actions/CancelInspections.vue';
import EquipmentSituationIcon from '@/components/snapshot/EquipmentSituationIcon.vue';
import ExtendInspection from '@/components/inspection/actions/ExtendInspection.vue';
import SubmitInspections from '@/components/inspection/actions/SubmitInspections.vue';
import BackToTopButton from '@/layouts/back-office/elements/BackToTopButton.vue';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import InspectionInspectorAvatar from '@/components/inspection/InspectionInspectorAvatar.vue';
import DownloadAuditReportAction from '@/components/audits/actions/DownloadAuditReportAction.vue';
import Audits from '../Audits.vue';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {getInspectionStatusTranslation} from '@/plugins/inspection/InspectionStatus';
import DeleteAuditAction from '@/components/audits/actions/DeleteAuditAction.vue';
import AuditInspections from '@/plugins/audit/AuditInspections';
import LastAudit from '@/components/planning/partials/LastAudit.vue';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import Card from '@/layouts/back-office/elements/Card.vue';
import Score from '@/components/planning/partials/Score.vue';
import EquipmentSituationStatus from '@/components/snapshot/EquipmentSituationStatus.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import AnomaliesAmount from '@/components/inspection/anomalies/AnomaliesAmount.vue';
import SendInspectionsReminderEmailAction from '@/components/dealer/actions/SendInspectionsReminderEmailAction.vue';
import SendAuditReminderEmailAction from '@/components/dealer/actions/SendAuditReminderEmailAction.vue';
import NotesCard from '@/components/notes/NotesCard.vue';
import CreateNote from '@/components/notes/Actions/CreateNote.vue';
import OnHoldLabel from '@/components/audits/OnHoldLabel.vue';

@Component({
    components: {
      OnHoldLabel,
      CreateNote,
      NotesCard,
      ActionBar,
      ActionButton,
      AnomaliesAmount,
      ApproveInspections,
      BackToTopButton,
      CancelInspections,
      Card,
      CloseAuditAction,
      DataTable,
      DeleteAuditAction,
      DownloadAuditReportAction,
      ExtendInspection,
      ExportSnapshots,
      EquipmentSituationIcon,
      EquipmentSituationStatus,
      InspectionInspectorAvatar,
      InspectionStatusLabel,
      LastAudit,
      NumberCard,
      QueryAutocomplete,
      QueryDataTable,
      Score,
      SendAuditReminderEmailAction,
      SendInspectionsReminderEmailAction,
      SubmitInspections,
      TransferSnapshots,
      TrustedLabel,
      UpdateInspections,
      Spinner,
    },
  })
  export default class PageAuditsDetail extends Vue {

    @Prop(String) private success!: string;
    @Prop({type: Array, default: () => []}) private errors!: string[];

    private audit: Audit_audit | null = null;
    private showError: boolean = false;

    private inspectionType = InspectionTypeEnum.FinalInspection;
    private stateAccept = InspectionApprovalState.ACCEPTED;
    private stateDecline = InspectionApprovalState.DECLINED;
    private statusExpired = InspectionStatus.EXPIRED;
    private statusOnHold = InspectionStatus.ON_HOLD;
    private statusClosed = AuditStatus.CLOSED;
    private branchName?: string | null;
    private getStatusTranslation = getInspectionStatusTranslation;
    private getStarRating = Audits.starRating;

    get title(): string {
      return this.branchName
        ? this.$it('audit.detail.title', 'Audit') + ` - ${this.branchName}`
        : this.$it('audit.detail.title', 'Audit') as string;
    }

    /**
     * The DataTable columns
     */
    get columns(): DataTableColumn[] {
      return [
        {
          title: '',
          key: 'id',
          class: 'data-table-col-fixed',
          width: 70,
        },
        {
          title: this.$it('snapshot.col.asset.title', 'Asset') as string,
          sortable: true,
          key: 'asset.name',
        },
        {
          title: this.$it('snapshot.col.inspector.title', 'Inspector') as string,
          key: 'inspector',
          class: 'data-table-col-min',
          sortable: true,
          width: 100,
        },
        {
          title: this.$it('snapshot.col.equipmentSituationStatus.title', 'Situation status') as string,
          slot: 'situationStatus',
          key: 'equipmentSituation',
          class: 'data-table-col-fixed data-table-col-center',
          sortable: 'equipmentSituation',
          width: 150,
        },
        {
          title: this.$it('snapshot.col.equipmentSituation.title', 'Situation') as string,
          key: 'equipmentSituation',
          class: 'data-table-col-fixed data-table-col-center',
          sortable: 'equipmentSituation',
          width: 150,
        },
        {
          title: this.$it('snapshot.col.anomaly.title', 'Anomalies') as string,
          key: 'anomaliesAmount',
          class: 'data-table-col-fixed data-table-col-center',
          width: 105,
          sortable: true,
        },
        {
          title: this.$it('snapshot.col.score.title', 'Score') as string,
          key: 'score',
          class: 'data-table-col-fixed data-table-col-center',
          width: 100,
          sortable: true,
          permission: 'inspections-score-get',
        },
        {
          title: this.$it('snapshot.col.status.title', 'Status') as string,
          key: 'status',
          class: 'data-table-col-fixed data-table-col-center',
          sortable: true,
          width: 120,
        },
      ];
    }

    /**
     * The DataTable filters
     */
    get filters(): Filter[] {
      return [
        {
          key: 'status',
          title: this.$it('snapshot.filter.status.title', 'Status') as string,
          type: FilterType.Select,
          options: this.$inspection.status.getFilterOptions([
            InspectionStatus.CREATED,
            InspectionStatus.PLANNED,
            InspectionStatus.STARTED,
            InspectionStatus.FINISHED,
            InspectionStatus.TRANSFER_PENDING,
            InspectionStatus.EXPIRED,
            InspectionStatus.SUBMITTED,
            InspectionStatus.DECLINED,
            InspectionStatus.ACCEPTED,
            InspectionStatus.CANCELLED,
          ]),
        },
        {
          key: 'equipmentSituation',
          title: this.$it('snapshot.filter.equipment-situation.title', 'Situation') as string,
          type: FilterType.Select,
          options: this.$snapshot.equipmentSituation.getFilterOptions([
            EquipmentSituation.FOUND,
            EquipmentSituation.SOLD,
            EquipmentSituation.TRANSFERRED,
            EquipmentSituation.IN_DEMONSTRATION,
            EquipmentSituation.IN_TRANSIT,
            EquipmentSituation.OTHER_SITUATION,
          ]),
        },
        {
          key: 'anomaly',
          title: this.$it('snapshot.filter.anomalies.title', 'Anomalies') as string,
          type: FilterType.Boolean,
          permission: 'inspections-warnings-get',
        },
      ];
    }

    /**
     * The DataTable searchable fields
     */
    get searchable(): DataTableSearchable[] {
      return [
        {
          key: 'id',
          name: '#',
        },
        {
          key: 'asset.name',
          name: this.$it('snapshot.col.asset.title', 'Asset') as string,
        },
        {
          key: 'asset.serial_number',
          name: this.$it('global.serial-number', 'Serial Number') as string,
        },
        {
          key: 'inspector.name',
          name: this.$it('snapshot.col.inspector.title', 'Inspector') as string,
        },
        {
          key: 'inspector.email',
          name: this.$it('snapshot.col.inspector-email.title', 'Inspector email') as string,
        },
      ];
    }

    get closedParams() {
      if (this.audit && this.audit.status === AuditStatus.CLOSED && this.audit.closedAt) {
        const closedAt = (this.audit.automaticallyClosedAt)
          ? this.$id(this.audit.automaticallyClosedAt, 'dateTime')
          : this.$id(this.audit.closedAt, 'dateTime');

        const closedBy = (this.audit.closedBy)
          ? this.audit.closedBy.name
          : this.$it('audit.closed-by.system.name', 'the system') as string;

        return {
          closedAt: `<strong>${closedAt}</strong>`,
          rating: this.getStarRating(this.audit!.rating),
          closedBy: `<strong>${closedBy}</strong>`,
        };
      }

      return {};
    }

    private getPostponeReason(reason: AuditPostponeReason) {
      switch (reason) {
        case AuditPostponeReason.NO_ASSETS_IN_STOCK:
          return this.$it('audit.reason.no-assets-in-stock', 'No assets in stock');
        case AuditPostponeReason.AUDIT_BY_EXTERNAL_PARTY:
          return this.$it('audit.reason.by-external-party', 'Audit by external party');
        case AuditPostponeReason.AUDIT_FREQUENCY_CHANGED:
          return this.$it('audit.reason.frequency-changed', 'Audit frequency changed');
      }
    }

    private resolveData({ audit }: Audit) {
      this.audit = audit;

      if (audit) {
        this.branchName = audit.branch.name;

        AuditInspections.setInspectionIds(audit.id, audit.inspections);
      }

      return audit;
    }

    private deleted() {
      this.$router.replace({ name: 'planning' });
    }

    @Meta
    private getMetaInfo() {
      return {
        title: this.title,
      };
    }
  }
