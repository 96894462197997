




















import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import FileUpload from '@/components/elements/input/FileUpload.vue';
import {CreateInspectionNoteVariables} from '@/types/intrador';

@Component({
  components: {FileUpload, InputTextarea, ActionModal},
})
export default class CreateNote extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title = this.$it('information.create.title', 'Create information') as string;
  public icon = 'sticky-note';

  @Prop(Object) protected mutation!: any;
  @Prop({type: Boolean, default: true}) protected uploadFile!: boolean;

  protected note: string = '';
  protected files: FileList|null = null;

  protected get variables(): CreateInspectionNoteVariables {
    return {
      id: this.selected[0].id,
      note: this.note,
      internal: true,
      file: (this.files) ? this.files[0] : null,
    };
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    this.note = '';

    modal.open();
  }
}
