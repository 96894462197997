























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PaginateAudits_paginateAudits_data_branch_endedAudits } from '@/types/intrador';
import LastAuditRating from '@/components/planning/partials/LastAuditRating.vue';

@Component({
  components: {
    LastAuditRating,
  },
})

export default class LastAudit extends Vue {
  @Prop({ type: Array, required: true }) private audits!: PaginateAudits_paginateAudits_data_branch_endedAudits[];
}
